import { ConstantMessage } from './constantMessage';

export class Constant {
  static readonly message = ConstantMessage;
  static readonly gridPageSize = 9;
  static readonly auditColumns = [
    'CreateDate',
    'CreatedBy',
    'ModifiedDate',
    'ModifiedBy'
  ];
  static readonly narrativeTypes = [
    {
      History: 1,
      HowToReach: 2,
      Description: 3,
      BusinessDetail: 4,
      BusinessGallery: 5,
      EventGallery: 6,
      EventDetail: 7,
      BusinessAreaSpecialization: 8
    }
  ];
  static readonly auditColumnsprime = [
    { field: 'createdDate', header: 'Created Date', type: 'date' },
    { field: 'createdName', header: 'Created By' },
    { field: 'modifiedDate', header: 'Modified Date', type: 'date' },
    { field: 'modifiedName', header: 'Modified By' }
  ];
}
