// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,

  baseUrl: "https://dev-api-gateway.brokernet.ie/api/",
  ///baseUrl: 'https://localhost:44329/api/',

   stsServer: 'https://optisins.auth.eu-west-1.amazoncognito.com',
  jwksUri: "https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_XsBJ5Ueem/.well-known/jwks.json",
  client_id: "4g53p2cbdfp026rjmjeakiej5m",
  issuer: "https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_XsBJ5Ueem",

  //   baseUrl:"https://uat-api-gateway.brokernet.ie/api/",
  //  stsServer :  'https://uat-optis.auth.eu-west-1.amazoncognito.com',
  //  jwksUri:"https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_Q9Tkpbnqg/.well-known/jwks.json",
  //  client_id:"4lsk521vi56eo0774l3oqhd2qt",
  //  issuer:"https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_Q9Tkpbnqg",
    froalaKey: 'Ig1A7vC3C1C2B2F2G4A3nB-31qawB-11tpH-8uC-21xD5B-13oD3whxC4B3A3A1B5A1D1E4F1A3==',
};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
