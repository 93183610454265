import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { catchError } from 'rxjs/operators';



@Injectable()
export class DataService {
  constructor(private http: HttpClient) { }

  get<T>(url: string, id?: number, header?: HttpHeaders): Observable<T> {
    if (header && header.keys.length > 0) {
      return this.http.get<T>(this.getUrl(url, id), { headers: header });
    } else {
      return this.http.get<T>(this.getUrl(url, id));
    }
  }

  // postPDF(url: string, data: any): Observable<any> {
  //   return this.httpDoc.post(this.getUrl(url), data, {
  //     responseType: ResponseContentType.Blob
  //   });
  // }

  // getPDF(url: string): Observable<any> {
  //   return this.httpDoc.get(this.getUrl(url), {
  //     responseType: ResponseContentType.Blob
  //   });
  // }


  post<T>(url: string, data: any, header?: HttpHeaders): Observable<T> {
    if (header && header.keys.length > 0) {
      return this.http.post<T>(this.getUrl(url), data, { headers: header });
    } else {
      return this.http.post<T>(this.getUrl(url), data);
    }
  }

  postPreview<T>(url: string, data: any, header?: HttpHeaders): Observable<T> {
    if (header && header.keys().length > 0) {
      return this.http.post<T>(this.getUrl(url), data, { headers: header });
    } else {
      return this.http.post<T>(this.getUrl(url), data);
    }
  }

  postBlob(url: string, data: any, header?: HttpHeaders): Observable<any> {
    if (header && header.keys.length > 0) {
      return this.http.post(this.getUrl(url), data, { responseType: 'blob' });
    } else {
      return this.http.post(this.getUrl(url), data, { responseType: 'blob' });
    }
  }

  put<T>(url: string, data: any, header?: HttpHeaders): Observable<T> {
    if (header && header.keys.length > 0) {
      return this.http.put<T>(this.getUrl(url), data, { headers: header });
    } else {
      return this.http.put<T>(this.getUrl(url), data);
    }
  }

  delete<T>(url: string, header?: HttpHeaders): Observable<T> {
    if (header && header.keys.length > 0) {
      return this.http.delete<T>(this.getUrl(url), { headers: header });
    } else {
      return this.http.delete<T>(this.getUrl(url));
    }
  }

  private extractData(res: Response) {
    return res.json();
  }

  private getUrl(url: string, id?: number): string {
    let finalurl = environment.baseUrl + url;
    if (id != null) {
      finalurl = finalurl + '/' + id;
    }
    return finalurl;
  }

  GetDocumentBlob(url: string, data: any): Observable<any> {
    return this.http.post(this.getUrl(url), data, { responseType: 'blob' });
  }

  postDocument<T>(url: string, data: any, header?: HttpHeaders): Observable<T> {
    if (header && header.keys.length > 0) {
      return this.http.post<T>(this.getUrl(url), data, { headers: header });
    } else {
      return this.http.post<T>(this.getUrl(url), data);
    }
  }

  postText(url: string, data: any, header?: HttpHeaders): Observable<any> {
    if (header && header.keys.length > 0) {
      return this.http.post(this.getUrl(url), data, { responseType: 'text' });
    } else {
      return this.http.post(this.getUrl(url), data, { responseType: 'text' });
    }
  }
}

