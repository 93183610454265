


import { Injectable, OnDestroy } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpRequest,
} from '@angular/common/http';

import {
  of as observableOf,
  Observable,
  throwError,
  Subject,
  BehaviorSubject,
  Subscription,
} from 'rxjs';
import { map, catchError, delay, tap } from 'rxjs/operators';
import { DataService } from '@shared/async-services/data.service';
import { AccessTypes, Authentication } from '@shared/models/auth/authentication.model';
import { environment } from '@env/environment';
// import { Jsonp } from '@angular/http';
@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // authUrl = this.configservice.get('api').baseUrl + '/auth';
  isAuthenticated = false;
  redirectUrl: string;
  userName: string;
  userImage: string;
  private isAuthorizedSubscription: Subscription = new Subscription();
  private _authentication: Authentication;
  private loginStatusChange = new Subject<any>();
  isLogin = new BehaviorSubject<boolean>(false);
  showfooter = new BehaviorSubject<boolean>(true);
  cachedRequests: Array<HttpRequest<any>> = [];
  public addOnOperationBasePath = "operation/";
  public get authentication(): Authentication {
    if (!this._authentication) {
      this._authentication = JSON.parse(
        window.sessionStorage.getItem('authentication')
      );
    }
    return this._authentication;
  }

  constructor(private http: HttpClient, private dataService: DataService) { }


  isLoggedIn(): Observable<boolean> {
    return this.isLogin.asObservable();
  }

  getClaims(claimName) {
    const auth = JSON.parse(window.sessionStorage.getItem('authentication'));
    if (auth && auth.userSystem && claimName) {
      const isExists = auth.claims.find(a => a.toString().toLowerCase() === claimName.toLowerCase());
      return isExists !== undefined && isExists !== null && isExists.length > 0;
    }
  }


  logout() {
    return this.http
      .post<boolean>(environment.baseUrl + '/logout', null)
      .pipe(
        map(loggedOut => {
          this.isAuthenticated = !loggedOut;
          window.sessionStorage.removeItem('Authentication');
          window.sessionStorage.removeItem('Authentication');
          return loggedOut;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err.error || 'Server error');
        })
      );


  }

  loginChange(): Observable<any> {
    return this.loginStatusChange.asObservable();
  }

  getAuthToken() {
    return window.sessionStorage.getItem('token');
  }


  autoredirect(key: string): Observable<any> {
    return this.dataService.get<boolean>('login/autoredirect?key=' + key).pipe(
      map((response) => {

        return true;
      })
    );
  }

  public setAuthentication(response: any) {
    window.sessionStorage.setItem("authentication", JSON.stringify(response));
  }


  getuseInfo(): Observable<any> {
    return this.dataService.get<any>(this.addOnOperationBasePath + 'login/userInfo?system=200').pipe(tap(response => {
      this.setAuthentication(response);
      this.isLogin.next(true);

    }));
  }



  public localLogout() {
    window.sessionStorage.removeItem('authentication');
    this._authentication = null;
    this.isLogin.next(false);
  }

  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }

  public retrieveFailedRequest(request): Array<HttpRequest<any>> {
    return this.cachedRequests;
  }

  canAcessMenu(claimType: string): boolean {
    const ret = this._authentication.claims.find(
      (c) => c.claimName.toLowerCase() === claimType
    );
    if (ret == null) {
      return false;
    }
    return ret.claimName.toLowerCase() === 'true';
  }

  // This method can be called a couple of different ways
  // *hasClaim="'claimType'"  // Assumes claimValue is true
  // *hasClaim="'claimType:value'"  // Compares claimValue to value
  // *hasClaim="['claimType1','claimType2:value','claimType3']"
  hasClaim(claimType: any, claimValue?: any) {
    let ret = false;
    // See if an array of values was passed in.
    if (typeof claimType === 'string') {
      ret = this.isClaimValid(claimType, claimValue);
    } else {
      const claims: string[] = claimType;
      if (claims) {
        for (let index = 0; index < claims.length; index++) {
          ret = this.isClaimValid(claims[index]);
          // If one is successful, then let them in
          if (ret) {
            break;
          }
        }
      }
    }

    return ret;
  }

  private isClaimValid(claimType: string, claimValue?: string): boolean {
    let ret = false;
    const auth = JSON.parse(window.sessionStorage.getItem('authentication'));

    // Retrieve security object

    if (auth && auth.claims) {
      // See if the claim type has a value
      // *hasClaim="'claimType:value'"
      if (claimType.indexOf(':') >= 0) {
        const words: string[] = claimType.split(':');
        claimType = words[0].toLowerCase();
        claimValue = words[1];
      } else {
        claimType = claimType.toLowerCase();
        // Either get the claim value, or assume 'true'
        claimValue = claimValue ? claimValue : 'true';
      }
      // Attempt to find the claim
      ret = auth.claims.find((c) => c.toLowerCase() === claimType) != null;
    } else {
      return false;
    }

    return ret;
  }

  public verifyEmail(emailaddr: string): Observable<any> {
    return this.dataService.get('Login/verifyEmail/' + emailaddr);
  }

  public sendforgetPasswordLink(emailaddr: string): Observable<any> {
    return this.dataService.get(
      'Email/sendforgetPasswordLink/' + emailaddr + '/2'
    );
  }

  public verifyforgetLink(messageid: string): Observable<any> {
    return this.dataService.get(
      'Login/validaterforgetPasswordLink/' + messageid
    );
  }

  public resetPassword(
    emailaddress: string,
    password: string
  ): Observable<any> {
    return this.dataService.get(
      'Login/resetPassword/' + emailaddress + '/' + password
    );
  }

  public validaterforgetPasswordLink(key: string): Observable<any> {
    return this.dataService.get('login/validaterforgetPasswordLink?key=' + key);
  }

  isManager() {
    return true;
  }

  ngOnDestroy(): void {
    if (this.isAuthorizedSubscription) {
      this.isAuthorizedSubscription.unsubscribe();
    }
  }
}
