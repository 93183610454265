import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Constant } from './constant';

@Injectable()
export class ToasterService {
  options: any;
  public constant = Constant;
  constructor(private toastr: ToastrService) {
    this.options = {
      showCloseButton: true,
      enableHTML: true,
    };
  }
  recordDeleted() {
    this.toastr.success(this.constant.message.deleteRecord);
  }

  recordSaved() {
    this.toastr.success(this.constant.message.saveRecord);

  }


  success(message: string, title?: string): void {
    this.toastr.success(message, '', { timeOut: 5000 });
  }
  info(message: string, title?: string): void {
    this.toastr.info(message,'', { timeOut: 5000 });
  }
  warning(message: string, title?: string): void {
    this.toastr.warning(message,'', { timeOut: 5000 });
  }
  error(message: string, title?: string): void {
    this.toastr.error(message, '', { timeOut: 5000 });
  }
}
